<template>
  <div class="product-info">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner4.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <!-- <p>Hardware</p> -->
        <h3>
          <span class="line-l"></span>
          <span class="center">{{ title }}</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <Swiper :list="list"></Swiper>
    </div>
  </div>
</template>
    
<script>
import Swiper from "@/components/Pc/swiper.vue";
export default {
  name: "",
  components: {
    Swiper: Swiper,
  },
  data() {
    return {
      list: [],
      title: "",
    };
  },
  methods: {
    gain() {
      this.title = this.$route.query.name;
      this.axios({
        method: "get",
        url: "/wzn/product/page",
        params: {
          pageNo: 1,
          pageSize: 100,
          categoryId: this.$route.query.id,
        },
      }).then((res) => {
        console.log("产品列表", res.data);
        this.list = res.data.data.list.reverse();
      });
    },
  },
  created() {
    this.gain();
  },
  // beforeRouteUpdate(to, from, next) {
  //   // console.log(to);
  //   // this.title = to.query.name;
  //   // this.$router.push({
  //   //   query: to.query,
  //   // });
  //   // this.axios({
  //   //   method: "get",
  //   //   url: "/wzn/product/page",
  //   //   params: {
  //   //     pageNo: 1,
  //   //     pageSize: 100,
  //   //     categoryId: to.query.id,
  //   //   },
  //   // }).then((res) => {
  //   //   console.log("产品列表", res.data);
  //   //   this.list = res.data.data.list.reverse();
  //   //   // this.$store.state.swiper.update(true);
  //   //   console.log(this.swiper);
  //   // });
  // },
  watch: {
    "$store.state.refresh"(value) {
      this.gain();
    },
  },
};
</script>
    
<style scoped>
/* .product-info {
  min-height: 100vh;
} */
</style>